import "./privacy.scss";


const Privacy = () => {

  return (

    <div className="div-1" >

        <nav className="navbarp">
        <h1 className="titulo">Políticas de privacidad</h1>
        </nav>

        <div className="div-2">
            <div class="alert" role="alert">
                Fecha de actualización: efectivo desde el 10 de diciembre de 2023
            </div>

            <div class="alert alert-primary" role="alert">
                INTRODUCCIÓN:
            </div>
              <p>
                 1. TVMAX es una aplicación OTT por la que se transmiten contenidos vía el servicio de streaming y puede ser visualizado tanto en dispositivos móviles, set top boxes, Smart TVs a través de la aplicación correspondiente, como así también sobre la web ingresando a https://web.tvmax.ec.
              </p>
              <p>
                     
    2. Esta política expresa la forma en que TVMAX recopila, utiliza y divulga su información personal.
             </p>
             <p>
                    
    3. Cada vez usted utiliza nuestros servicios de streaming, aplicaciones móviles, STB y en línea, visita nuestros sitios web, asiste a nuestras oficinas, accede a nuestras redes sociales, o se pone en contacto con nuestro servicio de atención al cliente (en conjunto, “Servicios”), podemos recopilar información personal.
             </p>
             <p>
                      
    4. Al utilizar cualquiera de los Servicios, usted reconoce las prácticas de recopilación de datos y los fines descritos en esta Política de privacidad.
             </p>
             <p>
                      
    5. Esta política se actualiza periódicamente, la última fecha de actualización es la que figura en el encabezado de este documento. 
             </p>
             <div class="alert alert-primary" role="alert">
                DEFINICIONES:
            </div>
            <p>

    1. INFORMACIÓN PERSONAL: toda información sobre una persona que la identifica o la hace identificable a través de medios que pueden ser razonablemente utilizados. 
            </p>
            <p>
                     
    2. MANEJO DE INFORMACIÓN PERSONAL: cualquier operación o procedimiento técnico, automatizado o no, que permite la recopilación, registro, organización, almacenamiento, conservación, elaboración, modificación, extracción, consulta, utilización, bloqueo, supresión, comunicación por transferencia o por difusión o cualquier otra forma de procesamiento que facilite el acceso, correlación o interconexión de la información personal.
            </p>
            <div class="alert alert-primary" role="alert">
                ADQUISICIÓN DE SU INFORMACIÓN PERSONAL:
            </div>
            <p>

    1. Recopilamos información en relación con el uso de los Servicios. Parte de esta información puede considerarse "Información personal" o "Datos personales" (tal como se define en la legislación vigente) que es información que lo identifica a usted o a su dispositivo, o que está de alguna manera asociada con usted. 
            </p>
            <p>
                     
    2. También recopilamos, utilizamos y divulgamos en forma de estadística, información anónima que no se considera información personal.
            </p>
            <p>
                     
    3. Cuando usted se registra en nuestros Servicios completa un formulario de solicitud mediante el cual podemos recopilar la información que nos proporciona, como su nombre, fecha de nacimiento, sexo y dirección de correo electrónico. 
            </p>
            <p>
                     
    4. Cuando usted adquiere una suscripción paga, recopilamos ciertos datos de pago y de verificación de identidad, como su nombre, su dirección de correo electrónico, su dirección física y los datos de su tarjeta de crédito.
            </p>
            <p>
                     
    5. Recopilamos información que nos proporciona cuando se pone en contacto con nosotros o nos hace llegar sus opiniones, comentarios o sugerencias sobre nuestros Servicios de forma directa.
            </p>
            <p>
                     
    6. En ocasiones, podemos recopilar información que usted nos proporcione sobre terceras personas, que puede incluir entre otros, nombres, correos electrónicos y fechas de nacimiento. 
            </p>
            <p>
                 
    7. Si usted es un proveedor de servicios, podemos recopilar información sobre usted y los servicios que presta, incluida su información de contacto comercial o la de sus empleados y demás información que usted o sus empleados nos proporcionen como parte de sus servicios.      
            </p>
            <p>
                     
    8. También recopilamos información relacionada con usted o que puede asociarse a usted, como contraseñas, preferencias e intereses personales, edad, sexo y cualquier otra información que usted decida proporcionar. 
            </p>
            <p>
                     
    9. Al utilizar nuestros servicios, incluso en plataformas de terceros, nosotros y nuestros proveedores de servicios que operan en nuestro nombre, podemos recopilar o recibir automáticamente cierta información asociada a usted o a su(s) dispositivo(s) de red, como su equipo, dispositivos móviles, Smart TV u otros dispositivos de reproducción. Esto incluye información sobre la forma en que utiliza nuestros Servicios y sus preferencias. Dicha información puede recopilarse automáticamente a través de tecnologías de seguimiento basadas en dispositivos, como cookies, píxeles, etiquetas, avisos, scripts u otras tecnologías.
            </p>
            <p>
                     
    10. La información que recopilamos automáticamente también puede incluir información de geolocalización, como (a) información que identifica la ubicación precisa de su dispositivo móvil y (b) su dirección IP, que puede utilizarse para estimar su ubicación aproximada. 
            </p>
             <p>
                      
    11. Adquirimos información de otras fuentes de confianza, como su proveedor de servicios de Internet, u otros proveedores de dispositivos de medios de transmisión que permiten ofrecer nuestros Servicios o contenidos a sus dispositivos, operadores de telefonía móvil u otras empresas que le prestan servicios. También podemos recopilar información sobre usted de otras fuentes, como proveedores de servicios, licenciatarios y agregadores de datos, empresas de marketing, socios publicitarios, distribuidores de programación, plataformas de redes sociales y bases de datos públicas.
             </p>
             <p>
                      
    12. Si se conecta a nosotros a través de una plataforma de redes sociales o se dirige a una plataforma de redes sociales desde uno de nuestros sitios, la plataforma de redes sociales recogerá su información por separado de la nuestra. Debe revisar las políticas de privacidad de las plataformas de redes sociales para entender cómo utilizan su información y sus derechos en relación con ella.
             </p>
             <div class="alert alert-primary" role="alert">
                USO DE SU INFORMACIÓN PERSONAL:
            </div>

            <p>

    1. Usamos su información personal para analizar su comportamiento en nuestras plataformas con el propósito de optimizar el rendimiento y funcionalidad de nuestros Servicios, por ejemplo ofrecer una grilla de contenidos optimizada a su nivel de membresía, tipo de dispositivo o localización geográfica.
            </p>
            <p>
                     
    2. Podemos además utilizar esta información para responder sus consultas o ponernos en contacto con usted, ofrecerle promociones, nuevos servicios u otras acciones de marketing.
            </p>
            <div class="alert alert-primary" role="alert">
                DIVULGACIÓN DE SU INFORMACIÓN PERSONAL:
            </div>
            <p>

    1. No lo hacemos.  Nosotros no compartimos su información personal con terceras partes, salvo que tengamos su consentimiento para hacerlo, con las siguientes excepciones:
    • En caso de un requerimiento de alguna autoridad legal.
    • En caso de que usted incurra en una violación a nuestros acuerdos de uso o políticas, para proteger propiedad intelectual tanto de TVMAX como de terceros.
    • En caso de un proceso de venta o fusión parcial o total, o cualquier otro tipo de adquisición, disposición o financiamiento en el que participe TVMAX, previo acuerdo de confidencialidad con la tercera parte involucrada en el proceso.
            </p>
            <p>
                2. Como se puede verificar, no compartimos información personal, sensible o confidencial, salvo datos sobre su dirección IP, dispositivo, proveedor de Internet o similar solamente a efectos de estadísticas o publicidad.
            </p>
            <p>
                     
    3. También podemos compartir información global, no relacionada con su persona, la cual no se considera información personal o confidencial.
            </p>
        
          
          <div class="alert alert-primary" role="alert">
            ALMACENAMIENTO DE SU INFORMACIÓN PERSONAL:
        </div>
        <p>

    1. Aplicamos razonablemente todas las medidas físicas, técnicas y administrativas para proteger la información personal que usted nos proporciona, ya sea contra perdida, robo, uso no autorizado, divulgación o modificación. Por ejemplo, las passwords se almacenan encriptadas y no son visibles para empleados o agentes de TVMAX.
        </p>
        <p>
                 
    2. Almacenamos la información que recolectamos en servidores locales como también en “la nube”, siguiendo altos estándares de seguridad informática y durante todo el tiempo que sea necesario para el propósito para el cual fue adquirida.  De la misma manera, mantenemos almacenamiento de información personal cumpliendo los requerimientos legales de cada país.
        </p>
        <p>
                 
    3. Más allá de todos los esfuerzos que podamos realizar, es de público conocimiento que no hay transmisión o almacenamiento de información que pueda garantizar un 100% de seguridad.  Queremos que se sienta confiado con el uso de nuestros Servicios, pero no podemos garantizar totalmente la seguridad de la información que nos proporcione. Remítase a la web de la Comisión Federal de Comercio de los Estados Unidos (www.ftc.com) para obtener información sobre como protegerse personalmente del robo de identidad.
    
        </p>

        <div class="alert alert-primary" role="alert">
            PRIVACIDAD PARA NIÑOS:
        </div>
        <p>

    1. No recolectamos conscientemente información sobre niños menores de trece (13) años.  
        </p>
        <p>
            2. Si usted es padre o tutor de un menor de trece (13) años y considera que nos ha provisto información personal sin su consentimiento, le solicitamos nos lo haga saber inmediatamente por cualquiera de nuestros medios de contacto.
    
        </p>
        <div class="alert alert-primary" role="alert">
            INFORMACIÓN PERSONAL RECOPILADA SOBRE LOS RESIDENTES DE CALIFORNIA:
        </div>

    1. Si usted es residente en California, de acuerdo con la Ley de Privacidad del Consumidor de California (CCPA) usted tiene derechos de privacidad específicos que incluyen:
    
    • Derecho a saber: Tiene derecho a solicitar un informe que muestre la información personal recopilada, compartida y vendida sobre usted en los últimos 12 meses.
    
    • Derecho a negarse a la venta de su información personal: Usted tiene derecho a negarse a la venta de sus datos personales a terceros. 
    
    • Derecho de eliminación: Tiene derecho a solicitar que eliminemos cualquier información personal que hayamos recopilado sobre usted. 
    • Derecho a la no discriminación: No lo discriminaremos por el ejercicio de ninguno de estos derechos.
        

        <p>
            
            2. Usted o su agente autorizado puede presentar una solicitud para ejercer cualquiera de los derechos anteriores a través del formulario https://web.tvmax.ec/privacy  o comunicándose por cualquiera de nuestros medios de contacto.
        </p>
        <p>
                 
    3. Podemos requerir información adicional para verificar su identidad, que resida en California o para verificar que su agente autorizado tenga la autoridad para hacer la solicitud en su nombre antes de responder a una solicitud de derechos conforme a la CCPA. Haremos nuestros mayores esfuerzos para responder a su solicitud en un plazo máximo de 45 días.
    
        </p>

        <div class="alert alert-primary" role="alert">
            REGISTROS HISTÓRICOS CCPA:
        </div>
        <p>

    1. Hasta octubre de 2023 no hemos recibido requerimientos sobre derechos específicos de privacidad de residentes del estado de California.
        </p>
        <div class="alert alert-primary" role="alert">
            CONTACTO PARA CONSULTA:
        </div>
        <p>

    1. Usted puede remitir su consulta por e-mail a <b>tv@tvmax.ec</b>  
    
        </p>
<br></br>
        </div>        
    </div>
          

   
  )
};

export default Privacy;
