import { useContext, useState } from "react";
import { login } from "../../authContext/apiCalls";
import { AuthContext } from "../../authContext/AuthContext";
import { Link } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import "./login.scss";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [mensaje, setMensaje] = useState("");

  const { dispatch } = useContext(AuthContext);

  let usert = useContext(AuthContext).error;

  const handleLogin = (e) => {
    e.preventDefault();
    if (email === "" || password === "") {
      setMensaje("Ingrese sus credenciales ...");
    }
    if (email !== "" && password !== "") {
      setMensaje("Validando credenciales ...");
      login({ email, password }, dispatch);
    }
  };

  const items = [
    { text: "Texto 1", img1: "https://via.placeholder.com/20", img2: "https://via.placeholder.com/20" },
    { text: "Texto 2", img1: "https://via.placeholder.com/20", img2: "https://via.placeholder.com/20" },
    { text: "Texto 3", img1: "https://via.placeholder.com/20", img2: "https://via.placeholder.com/20" },
    { text: "Texto 4", img1: "https://via.placeholder.com/20", img2: "https://via.placeholder.com/20" }
  ];

  return (
    <div className="logint">
      <div className="top">
        <div className="wrapper">
          <img
            className="logo"
            src="https://tv2.yottalan.com/media/images/users/tvmax.png"
            alt=""
          />
        </div>
      </div>
      <div className="container">
        <form>
          <h1>Iniciar Sesión</h1>
          <input
            type="email"
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <button className="loginButton" onClick={handleLogin}>
            Ingresar
          </button>
          <span>
            <Link to="/register"><b>Crear cuenta.</b></Link>
          </span>
          <span>
            <Link to="/recuperar"><b>Recuperar cuenta.</b></Link>
          </span>
          <span>
            <Link to="/delete"><b>Eliminar cuenta.</b></Link>
          </span>
          <span>{usert}</span>
        </form>

        <Grid 
          container 
          spacing={2} 
          justifyContent="center" 
          alignItems="center"
          style={{ marginTop: "20px" }}
        >
          {/* Empty columns to center the content */}
          

          <Grid item xs={2}  style={{ textAlign: "center" }}>
              <h3>Desde tu movil Android</h3>
              <a href="https://play.google.com/store/apps/details?id=com.nextwarela.moviltvmax" target="_blank" rel="noopener noreferrer">  
              <img src="https://tv.yottalan.com/assets/img/android.png" alt={`android`} style={{ height: "40px", marginTop: "10px" }} />
              </a>
          
          </Grid>
          <Grid item xs={2}  style={{ textAlign: "center" }}>
              <h3>Desde tu movil IOS</h3>
              <a href="" target="_blank" rel="noopener noreferrer">
              <img src="https://tv2.yottalan.com/media/images/users/descargasios.png" alt={`ios`} style={{ height: "45px", marginTop: "10px" }} />
              </a>
          </Grid>
          <Grid item xs={2}  style={{ textAlign: "center" }}>
              <h3>Desde tu TV Android</h3>
              
              <a href="https://play.google.com/store/apps/details?id=com.nextwarela.tvmaxstb" target="_blank" rel="noopener noreferrer">
              <img src="https://tv.yottalan.com/assets/img/android.png" alt={`android`} style={{ height: "40px", marginTop: "10px" }} />
              </a>
          </Grid>
          <Grid item xs={2}  style={{ textAlign: "center" }}>
              <h3>Desde tu STB</h3>
              <a href="tvmaxtv-stb-tv.apk" download target="_blank" rel="noopener noreferrer">
              <img src="https://tv2.yottalan.com/media/images/users/descargas.png" alt={`android`} style={{ height: "40px", marginTop: "10px" }} />
              </a>
          </Grid>
          
          
        </Grid>
      </div>
    </div>
  );
}
